/* ContactForm.css */
.contactForm {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    padding-top: 0rem;
    color: white;
    background-color: #022852;
    border-radius: 0.7rem;
    box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.25),
    -1px 3px 9px rgba(0, 0, 0, 0.4);
    margin-bottom: 2rem;
    /* Combine all transitions */
    transition: margin-bottom 0.5s ease-out, 
                transform 1s cubic-bezier(0.25, 0.8, 0.25, 1), 
                box-shadow 0.9s ease;
    will-change: transform, box-shadow;
}

.preference-wrapper {
    display: flex;
    flex-direction: column;
}

.consent-statement {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 450px;
    align-self: center;
    font-size: 0.9rem;
    color: white;
    margin-top: 0.4rem;  /* Adds spacing below the dropdown */
    line-height: 1.4;
    word-wrap: break-word;
    text-align: center;  /* Aligns with the dropdown */
}

.consent-statement .consent {
    color: inherit;
}

.consent-statement a {
    color: inherit;
    font-weight: bold;
    transition: color 0.15s ease;
}

.consent-statement a:hover {
    color: #e4a906;
}

.form-fields {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.info-note {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    color: rgb(167, 167, 167);
    font-size: 1rem;
}

.contactForm.messageVisible {
    margin-top: 5rem; /* Adjust as needed */
}

.contactForm:hover {
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.4),
    -3px 5px 6px rgba(0, 0, 0, 0.45);
    transform: scale(1.02);
    /* No need to redeclare transitions on hover */
}


.contactForm .form-group {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
}

.contactForm .form-group label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1.1rem;
    transition: color 0.3s ease
}

.contactForm .form-group input,
.contactForm .form-group select,
.contactForm .form-group textarea {
    box-sizing: border-box; /* Include padding and border in height calculation */
    height: 3rem; /* Explicit height */
    padding: 0.7rem; /* Adjust padding as necessary */
    outline: 0.13rem solid rgba(255, 255, 255, 0.26); /* Consistent border */
    border: none;
    border-radius: 0.7rem;
    font-size: 1.08rem;
    font-weight: 700;
    width: 100%;
    transition: outline 0.4s ease;
    background-color: rgb(1, 0, 29);
    color: white;
    color-scheme: dark;
    will-change: color, transform;
}


.picker {
  cursor: pointer;
}


.form-instructions {
    z-index: 1; /* Ensure it's behind the DatePicker */
    position: relative;

    p {
        font-size: 1rem;
        margin: 0;
    }
    
}

.key-red {
    border-bottom: 2px solid red;
    font-weight: bold;
    color: inherit !important;
    position: relative; /* Ensure it follows the parent positioning */
    z-index: inherit; /* Inherit the z-index of the parent */
}


/* Adjust textarea height separately */
.contactForm .form-group textarea {
    height: auto; /* Let height grow as needed */
    min-height: 8rem; /* Minimum height */
    resize: vertical;
    transition: outline 0.4s ease;
}
.contactForm .form-group input:focus,
.contactForm .form-group select:focus,
.contactForm .form-group textarea:focus {
    outline: 0.13rem solid #e4a906;
    border: none;
    transition: outline 0.4s ease;
    will-change: color, transform;

    label {
        color: #e4a906;
        transition: color 0.3s ease
    }
}

.contactForm .form-group select {
    cursor: pointer;
}

.labelNote {
    text-decoration: none !important;
    color: rgb(167, 167, 167) !important;

}


.contactForm .form-group label.focused {
    color: #e4a906;
}


.contactForm button[type="submit"] {
    background-color: #e4a906;
    color: #022852;
    padding: 0.75rem 1.5rem;
    border: 1px solid rgb(1, 0, 29);
    border-radius: 0.7rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 800;
    transition: 0.3s ease;
    font-size: 1.5rem;

}

.contactForm button[type="submit"]:disabled {
    background-color: #e4a90686;
    color: #02285286;
    padding: 0.75rem 1.5rem;
    border: 1px solid rgb(1, 0, 29);
    border-radius: 0.7rem;
    cursor: not-allowed;
    font-size: 1rem;
    font-weight: 800;
    transition: 0.3s ease;
    font-size: 1.5rem;
}

.contactForm button[type="submit"]:disabled:hover {
    background-color: #e4a90686;
    color: #02285286;
    border: 1px solid rgb(1, 0, 29);
}

.contactForm button[type="submit"]:hover {
    background-color: rgb(1, 0, 29);
    color: #e4a906;
    border-color: #e4a906;
}

.input-with-icon {
    display: flex;
    position: relative;
    will-change: color, transform;
}

.input-with-icon i {
    position: absolute;
    left: -2rem;
    bottom: 1rem;
    color: #e4a906;
    will-change: color, transform;
}

.input-with-icon .description-icon {
    margin-bottom: 5.6rem;
    font-size: large;
}

.is-invalid {
    outline-color: rgba(197, 0, 0, 0.808) !important;
}

.is-valid {
    outline-color: #e4a906 !important; /* The border color for valid fields */
}

.icon-valid {
    color: #e4a906; /* Existing color for valid fields */
}

.icon-invalid {
    color: rgb(197, 0, 0) !important; /* Color for invalid fields */
}

.captcha-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}


.submissionMessage {
    animation: fadeInOut 5s forwards;
}
  
  @keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }

  
@media (max-width: 300px) {

    .contactForm {
      padding: 0.7rem;
    }
  
}
