.privacy-policy-page {
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
}

.privacy-section {
    width: 100%;
    padding: 0;
}

.privacy-policy-page h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
    color: #0056b3;
}

.privacy-policy-page h3 {
    font-size: 1.4rem;
    margin-top: 20px;
    color: #444;
}

.privacy-policy-page p, 
.privacy-policy-page ul {
    font-size: 1rem;
    margin-bottom: 15px;
}

.privacy-policy-page ul {
    padding-left: 20px;
}

.privacy-policy-page ul li {
    margin-bottom: 10px;
}

.privacy-policy-page a {
    color: #0056b3;
    text-decoration: none;
    font-weight: bold;
}

.privacy-policy-page a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .privacy-policy-page {
        width: 90%;
        padding: 15px;
    }

    .privacy-policy-page h2 {
        font-size: 1.8rem;
    }

    .privacy-policy-page h3 {
        font-size: 1.3rem;
    }

    .privacy-policy-page p, 
    .privacy-policy-page ul {
        font-size: 0.95rem;
    }
}
